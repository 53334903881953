export default `
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
  }

  h1 {
    font-size: 48px !important;
  }

  h2 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 24px !important;
  }

  a {
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
  }

  #title {
    letter-spacing: 0.4px !important;
    font-size: 22px !important;
    font-size: 1.375rem !important;
    line-height: 1.13636 !important;
  }

  .uk-navbar-container {
    background: #fff !important;
    font-family: Staatliches !important;
  }

  img:hover {
    opacity: 1 !important;
    transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) !important;
  }
  .banner-bg {
    position: absolute !important;
    width: calc(100% - 2.5rem) !important;
    height: 800px !important;
  }
`;
